@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--colorFail);
}

.program {
  margin: 24px 0;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--colorGrey200);
}

.programName {
  flex-grow: 1;
}

.programNameInput {
}

.description {
  font-size: 16px;
  margin: 0;
  font-weight: var(--fontWeightRegular);
  color: var(--colorGrey300);
}

.videoTitle {
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin-bottom: 0;
}

.videoContainer {
  margin: 24px 0 24px 0;
  position: relative;
}

.video {
  object-fit: cover;
  height: 500px;
  width: 100%;
  border-radius: 10px;
}

.waitListForm {
  margin: 12px 0 0 0;
}

.preCreatedApplicationFormLink,
.preCreatedWaitlistFormLink {
  text-decoration: underline;
  cursor: pointer;
}

.removeProgramBtn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  text-decoration: underline;
  color: #ff7d5f;
  font-weight: var(--fontWeightRegular);
}

.row {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  align-items: flex-start;
}

.ageWrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.ageSelect {
  width: 120px;
}

.ageTo {
  margin-top: 12px;
}

.available {
  margin: 0 12px 0 0;
}

.monthYearLabel {
  composes: label from global;
}

.description {
  width: 100%;
  color: inherit;
}

.depositamount,
.monthlyFee {
  width: 200px;
}

.applyOptionContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.applyOption {
  align-self: flex-start;
}

.month,
.year {
  width: 200px;
}

.addAnotherButton {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
}

.addProgramButton {
  margin-top: 24px;
  width: 170px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  & legend {
    display: contents;
  }
  & ul {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.expired {
  color: #e10000;
  font-size: 12px;
  font-weight: var(--fontWeightBold);
  margin: 0;
}

.availabilityExpired {
  & select {
    border: 1px solid #e10000;
  }
}

.formDisabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;

  input,
  select,
  textarea {
    background-color: var(--colorGrey50);
    cursor: not-allowed;
    pointer-events: none;
  }
}
